import './style.css';
import 'ol/ol.css';
import 'ol-layerswitcher/dist/ol-layerswitcher.css';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import { Fill, Icon, Style, Circle, Stroke } from 'ol/style';
import LayerGroup from 'ol/layer/Group';
import LayerTile from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import SourceOSM from 'ol/source/OSM';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Control from 'ol/control/Control';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { circular } from 'ol/geom/Polygon';
import kompas from 'kompas';
import { fromLonLat, Projection } from 'ol/proj';
import ImageWMS from 'ol/source/ImageWMS';
import Image from 'ol/source/Image';
import { transformExtent } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import proj4 from 'proj4';
import LayerSwitcher from 'ol-layerswitcher';
import { BaseLayerOptions, GroupLayerOptions } from 'ol-layerswitcher';
import ImageLayer from 'ol/layer/Image';
import { ScaleLine, defaults as defaultControls } from 'ol/control';
import Link from 'ol/interaction/Link';
import GeoJSON from 'ol/format/GeoJSON';
import { bbox as bboxStrategy } from 'ol/loadingstrategy';
import { PopupFeature } from 'ol-ext/overlay/PopupFeature';
import { Popup } from 'ol-ext/overlay/Popup';
import Vector from 'ol/source/Vector';
import Collection from 'ol/Collection';
import LongTouch from 'ol-ext/interaction/LongTouch';
import Zoom from 'ol-ext/featureanimation/Zoom';

import { easeOut } from 'ol/easing';
proj4.defs("EPSG:25832", "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs");
register(proj4);


const scale = new ScaleLine({
	units: 'metric',
	bar: true,
	steps: 2,
	text: true,
	minWidth: 140
});

const vectorSource = new VectorSource({
	format: new GeoJSON(),
	url: function(extent) {
		return (
			'https://ahocevar.com/geoserver/wfs?service=WFS&' +
			'version=1.1.0&request=GetFeature&typename=osm:water_areas&' +
			'outputFormat=application/json&srsname=EPSG:3857&' +
			'bbox=' +
			extent.join(',') +
			',EPSG:3857'
		);
	},
	strategy: bboxStrategy,
});
const source = new VectorSource();
const layer = new VectorLayer({
	source: source,
});
const view = new View({
	center: [0, 0],
	zoom: 2,
});

const osmbase = new LayerTile({
	title: 'OSM Base',
	type: 'base',
	visible: false,
	source: new SourceOSM()
} as BaseLayerOptions);


const orthobase = new ImageLayer({
	title: 'Ortho Base',
	type: 'base',
	visible: false,
	source: new ImageWMS({
		url: 'https://geo.xitio.de/geoserver/rlp/wms',
		params: {
			'FORMAT': "image/jpeg",
			'VERSION': '1.1.1',
			"STYLES": '',
			"LAYERS": 'rlp:wms_rp_dop40',
			"exceptions": 'application/vnd.ogc.se_inimage',
		}
	})
} as BaseLayerOptions);


const ortho = new ImageLayer({
	title: 'Ortho',
	visible: true,
	source: new ImageWMS({
		url: 'https://geo.xitio.de/geoserver/rlp/wms',
		params: {
			'FORMAT': "image/jpeg",
			'VERSION': '1.1.1',
			"STYLES": '',
			"LAYERS": 'rlp:wms_rp_dop40',
			"exceptions": 'application/vnd.ogc.se_inimage',
		}
	})
} as BaseLayerOptions);


const bplan = new ImageLayer({
	title: 'Ergänzungssatzung 2005',
	visible: false,
	source: new ImageWMS({
		url: 'https://geo.xitio.de/geoserver/rlp/wms',
		params: {
			'FORMAT': "image/jpeg",
			'VERSION': '1.1.1',
			"STYLES": '',
			"LAYERS": 'rlp:07233217_Kaperich',
			"exceptions": 'application/vnd.ogc.se_inimage',
		}
	})
} as BaseLayerOptions);





const likabase = new LayerTile({
	title: 'Liegenschaftskataster Base',
	type: 'base',
	visible: true,
	minZoom: 15.4,
	source: new TileWMS({
		url: 'https://geo.xitio.de/geoserver/rlp/wms',
		params: {
			'VERSION': '1.1.1',
			tiled: true,
			"STYLES": '',
			"LAYERS": 'rlp:WMS_RP_ALKIS_Liegenschaften',
		}
	})
} as BaseLayerOptions);



const lika = new LayerTile({
	title: 'Liegenschaftskataster',
	visible: false,
	minZoom: 15.4,
	source: new TileWMS({
		url: 'https://geo.xitio.de/geoserver/rlp/wms',
		params: {
			'VERSION': '1.1.1',
			tiled: true,
			"STYLES": '',
			"LAYERS": 'rlp:WMS_RP_ALKIS_Liegenschaften',
		}
	})
} as BaseLayerOptions);

const kultur = new LayerTile({
	title: 'Kulturdenkmäler',
	visible: false,
	source: new TileWMS({
		url: 'https://geo.xitio.de/geoserver/rlp/wms',
		params: {
			'FORMAT': "image/png",
			'VERSION': '1.1.1',
			tiled: true,
			"STYLES": '',
			"LAYERS": 'rlp:kulturdenkmäler',
			"exceptions": 'application/vnd.ogc.se_inimage',
			tilesOrigin: 284672.9069406443 + "," + 5420274.77287756
		}
	})
} as BaseLayerOptions);


//https://geo.xitio.de/geoserver/rlp/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=rlp%3Ams_kdb_archaeologie&maxFeatures=50&outputFormat=application%2Fjson



const topo = new TileLayer({

	title: 'Topo',
	visible: false,
	source: new TileWMS({
		url: 'https://geo.xitio.de/geoserver/rlp/wms',
		params: {
			'FORMAT': 'image/png',
			'VERSION': '1.1.1',
			tiled: true,
			"STYLES": '',
			"LAYERS": 'rlp:topo',
			"exceptions": 'application/vnd.ogc.se_inimage',
			tilesOrigin: 0 + "," + 4100000
		}
	})
} as BaseLayerOptions);



const hoehe = new TileLayer({
	title: 'Höhenlinien',
	visible: false, source: new TileWMS({
		url: 'https://geo.xitio.de/geoserver/rlp/wms',
		params: {
			'VERSION': '1.1.1',
			'LAYERS': 'rlp:hoehe',
			'TILED': true
		},
		serverType: 'geoserver',
		transition: 0,
	}),
} as BaseLayerOptions);



const schummer = new TileLayer({

	title: 'Schummerung',
	visible: false,
	//minZoom: 14,
	maxZoom: 17.88033679795187,
	source: new TileWMS({
		url: 'https://geo.xitio.de/geoserver/rlp/wms',
		params: {
			'VERSION': '1.1.1',
			'LAYERS': 'rlp:schummerung',
			'FORMAT': 'image/png',
			'TILED': true
		},
		projection: 'EPSG:25832',
		serverType: 'geoserver',
		transition: 0
	}),
} as BaseLayerOptions);

const basis = new TileLayer({

	title: 'Basisraster LGB',
	visible: false,
	//minZoom: 14,
	source: new TileWMS({
		url: 'https://geo.xitio.de/geoserver/rlp/wms',
		params: {
			'VERSION': '1.1.1',
			'LAYERS': 'rlp:basisraster',
			'FORMAT': 'image/png',
			'TILED': true
		},
		projection: 'EPSG:25832',
		serverType: 'geoserver',
		transition: 0
	}),
} as BaseLayerOptions);

const basisbase = new TileLayer({

	title: 'Basisraster LGB Base',
	visible: false,
	type: 'base',
	source: new TileWMS({
		url: 'https://geo.xitio.de/geoserver/rlp/wms',
		params: {
			'VERSION': '1.1.1',
			'LAYERS': 'rlp:basisraster',
			'FORMAT': 'image/png',
			'TILED': true
		},
		projection: 'EPSG:25832',
		serverType: 'geoserver',
		transition: 0
	}),
} as BaseLayerOptions);


const opacitySelect = document.createElement('div');
opacitySelect.className = 'ol-control ol-unselectable opac';
opacitySelect.innerHTML = '<input orient="vertical"  id="opacity-input" type="range" min="0" max="1" step="0.01" value="0.8" /><span hidden="true" id="opacity-output"></span>';

function update() {
	const opacity = parseFloat(opacitySelect.firstChild.value);
	basis.setOpacity(opacity);
	schummer.setOpacity(opacity);
	hoehe.setOpacity(opacity);
	lika.setOpacity(opacity);
	likabase.setOpacity(opacity);
	topo.setOpacity(opacity);
	//ortho.setOpacity(opacity);
	opacitySelect.lastChild.innerText = opacity.toFixed(2);
}
opacitySelect.addEventListener('input', update);
update();
const format = "image/jpeg";


const preussen = new TileLayer({
	title: 'Preußische (1843-1878)',
	visible: false,
	source: new TileWMS({
		url: 'https://geo.xitio.de/geoserver/rlp/wms',
		params: {
			'FORMAT': format,
			'VERSION': '1.1.1',
			tiled: true,
			"STYLES": '',
			"LAYERS": 'rlp:preussen',
			"exceptions": 'application/vnd.ogc.se_inimage',
			tilesOrigin: 285000 + "," + 5485000
		}
	})
} as BaseLayerOptions);



const tranchot = new TileLayer({
	title: 'Tranchot von Müffling (1803 - 1820)',
	visible: false,
	source: new TileWMS({
		url: 'https://geo.xitio.de/geoserver/rlp/wms',
		params: {
			'FORMAT': format,
			'VERSION': '1.1.1',
			tiled: true,
			"STYLES": '',
			"LAYERS": 'rlp:tranchot',
			"exceptions": 'application/vnd.ogc.se_inimage',
			tilesOrigin: 285000 + "," + 5485000
		}
	})
} as BaseLayerOptions);



const style = new Style({
	fill: new Fill({
		color: 'rgba(0, 0, 255, 0.2)',
	}),
	image: new Icon({
		src: './data/location-heading.svg',
		imgSize: [27, 55],
		rotateWithView: true,
	}),
});
layer.setStyle(style);
const baseMaps = new LayerGroup({
	title: 'Ebenen',
	layers: [bplan, ortho,preussen, tranchot, hoehe, topo, schummer, basis, lika, kultur , basisbase,orthobase,likabase,osmbase]
} as GroupLayerOptions);

const locate = document.createElement('div');
locate.className = 'ol-control ol-unselectable locate';
locate.innerHTML = '<button title="Locate me">◎</button>';
locate.addEventListener('click', function() {
	console.info(source)
	if (!source.isEmpty()) {
		map.getView().fit(source.getExtent(), {
			maxZoom: 18,
			duration: 500,
		});
	}
});
function startCompass() {
	kompas()
		.watch()
		.on('heading', function(heading) {
			style.getImage().setRotation((Math.PI / 180) * heading);
		});
}

if (
	window.DeviceOrientationEvent &&
	typeof new DeviceOrientationEvent(this) === 'function'
) {
	locate.addEventListener('deviceorientation', function() {
		DeviceOrientationEvent.call(this)
			.then(startCompass)
			.catch(function(error) {
				alert(`ERROR: ${error.message}`);
			});
	});
} else if ('ondeviceorientationabsolute' in window) {
	startCompass();
} else {
	alert('No device orientation provided by device');
}
const map = new Map({
	target: 'map',
	layers: [baseMaps],
	view: view,
});
const layerSwitcher = new LayerSwitcher({
	reverse: true,
	groupSelectStyle: 'group',
	startActive: true, activationMode: 'mouseover'
});
map.addControl(layerSwitcher);
map.addControl(scale);
map.addLayer(layer);


navigator.geolocation.watchPosition(
	function(pos) {
		const coords = [pos.coords.longitude, pos.coords.latitude];
		const accuracy = circular(coords, pos.coords.accuracy);
		source.clear(true);
		source.addFeatures([
			new Feature(
				accuracy.transform('EPSG:4326', map.getView().getProjection())
			),
			new Feature(new Point(fromLonLat(coords))),
			new Feature(new Point(fromLonLat(coords))),
			new Feature(new Point(fromLonLat(coords))),
		]);
		console.info(coords);
	},
	function(error) {
		console.error(`ERROR: ${error.message}`);
	},
	{
		enableHighAccuracy: true,
	}
);

function gohere() {
	var s = document.getElementById("locToGoTo").value;
	var vals = s.split(",");
	var lon = vals[0].split("N").join("").split("°").join("").trim();
	var lat = vals[1].split("E").join("").split("°").join("").trim();
	console.info(s)
	console.info(lon)
	console.info(lat)
	// Converting lat long to mercator projection
	const proj_lat_long = fromLonLat([lat, lon])

	var f = new Feature(new Point(proj_lat_long));
	vector.getSource().addFeature(f);
	pulseFeature(proj_lat_long);
	setTimeout(function() { pulseFeature(proj_lat_long); }, 1000);
	map.setView(
		new View({
			center: proj_lat_long,
		}));

	map.getView().animate({
		zoom: 17.88033679795187,
		duration: 250
	})

}

function gohome() {
	var lat = 7.0251529;
	var lon = 50.2269527;

	// Converting lat long to mercator projection
	const proj_lat_long = fromLonLat([lat, lon])

	map.setView(
		new View({
			center: proj_lat_long,
		}));

	map.getView().animate({
		zoom: 17.88033679795187,
		duration: 250
	})

}



const maxSchummer = document.createElement('div');
maxSchummer.className = 'ol-control ol-unselectable maxSchummer';
maxSchummer.innerHTML = '<button title="Best Schummer">⛬</button>';
maxSchummer.addEventListener('click', function() {
	if (!source.isEmpty() && schummer.getVisible() == false) {
		schummer.setVisible(true)
		map.getView().animate({
			zoom: 17.88033679795187,
			duration: 250
		})
	} else {
		if (schummer.getVisible() == true) {

			schummer.setVisible(false)
		}
	}
});


const home = document.createElement('div');
home.className = 'ol-control ol-unselectable home';
home.innerHTML = '<button id="centerme" title="centerme">⌂</button>';
home.addEventListener(
	'click',
	gohome,
	false
);


const here = document.createElement('div');
here.className = 'ol-control ol-unselectable here';
here.innerHTML = '<button id="centerhere" title="centerhere">⌖</button><input id="locToGoTo" type="text" value="50.22163° N,7.02129° E"  onfocus="this.value=\'\'" ></input';
here.addEventListener(
	'click',
	gohere,
	false
);


map.addControl(
	new Control({
		element: opacitySelect,
	})
);


map.addControl(
	new Control({
		element: locate,
	})
);
map.addControl(
	new Control({
		element: maxSchummer,
	})
);
map.addControl(
	new Control({
		element: home,
	})
);
map.addControl(
	new Control({
		element: here,
	})
);
map.on('click', function(evt) {
	// console.info(map.getView().getZoom()) ;


});

gohome();
map.on('moveend', function(evt) {

	//let bbox = map.getView().calculateExtent();
	//console.log(bbox);

	//let bbox25832 = transformExtent(bbox, 'EPSG:3857', 'EPSG:25832')
	//console.log(bbox25832);
	console.info(map.getView().getZoom());
});

map.addInteraction(new Link());



// New vector layer
const vector = new VectorLayer({
	source: new Vector({ features: new Collection() }),
	updateWhileInteracting: true
})
map.addLayer(vector);

function pulseFeature(coord) {
	const p = new Point(coord);
	const f = new Feature(p);
	f.setStyle(new Style({
		image: new Circle({
			radius: 60,
			stroke: new Stroke({ color: "red", width: 3 })
		})
	}));
	map.animateFeature(f, new Zoom({
		fade: easeOut,
		duration: 800,
	}));
};

// Longtouch interaction
var touchi = new LongTouch({
	pixelTolerance: 1,
	// Handle longtouch > create a new feature
	handleLongTouchEvent: function(e) {
		var f = new Feature(new Point(e.coordinate));
		vector.getSource().addFeature(f);
		pulseFeature(e.coordinate);
		setTimeout(function() { pulseFeature(e.coordinate); }, 400);
	}
});
map.addInteraction(touchi);

// Listen to longtouch event on the map
map.on(['longtouch'], function(e) {
	console.log(e);
})

